import CardCount from "src/components/CardCount";
import PageContainer from "../../components/routes/PageContainer";
import { useContext, useEffect, useState } from "react";
import { Notification } from "@rio-cloud/rio-uikit";
import { Dialog, TableTrips } from "@components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchCanceledTrip, fetchTripsList } from "src/services/trips";
import ConfirmationDialog from "@rio-cloud/rio-uikit/ConfirmationDialog";
import { getFollowers, getFollowings } from "src/services/social";
import { Client } from "@amityco/ts-sdk";
import { useAppSelector } from "@store";

const TripsScreen = () => {
  const profile = useAppSelector(state => state.login.userProfile);
  const [routes, setRoutes] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowings] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("desc");
  const [fieldSort, setFieldSort] = useState("name");
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [pages, setPages] = useState(1);
  const [show, setShow] = useState(false);
  const [idDelete, setIdDelete] = useState<undefined | string>("");
  const [dashboard, setDashboard] = useState({
    scheduled: 0,
    new: 0,
    pending: 0,
    refused: 0,
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const navigate = useNavigate();
  const [search] = useSearchParams();

  const handleUpdateRoute = (id: string) => {
    const currentParams = new URLSearchParams(search);
    currentParams.set("id", id);
    const url = "/routing/routes/update?" + currentParams.toString();
    navigate(url);
    // window.location.href = url;
  };

  const handleCanceled = async () => {
    try {
      const date = new Date().toISOString();
      await fetchCanceledTrip(
        { deletedAt: date, updatedAt: date },
        idDelete as string
      );
      initialData();
      setShow(false);
    } catch (error) {
      Notification.error("Não foi possível buscar as rotas.");
    }
  };

  const handleSelectTrip = () => {
    navigate("/routing/trips/routes");
  };

  const initialData = async () => {
    try {
      const res = await fetchTripsList(page, 10, searchValue, sort, fieldSort);
      if (res.data.result) {
        setRoutes(res.data?.result?.trips);
        setDashboard({
          pending: res.data.result.pending,
          scheduled: res.data.result.schedules,
          new: res.data.result.suggestion,
          refused: res.data.result.refused,
        });

        setPages(res.data.result?.pages > 0 ? res.data.result?.pages : 1);
      }
    } catch (error) {
      Notification.error("Não foi possível buscar as rotas.");
    }
  };

  useEffect(() => {
    initialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchValue, sort]);

  const handleDownload = () => {
    console.log("donwload");
  };

  const fetchFollowerAndFollowing = async () => {
    try {
      const resFollowers = await getFollowers(
        profile?.userId
      );
      const resFollowing = await getFollowings(
        profile?.userId
      );
      setFollowers(resFollowers.data.result.follows);
      setFollowings(resFollowing.data.result.follows);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    fetchFollowerAndFollowing();
  }, [Client.getActiveClient().token?.accessToken]);

  return (
    <PageContainer>
      <div style={{ display: "flex", gap: 24 }}>
        {/* <div style={{ width: 400 }}>
          <CardCount
            borderColor="#5CB85C"
            label="VIAGENS AGENDADAS"
            value={dashboard.new}
          />
        </div> */}
        {/* <CardCount
          borderColor="#FF8E3C"
          label="NOVA DIA E HORA SUGERIDOS"
          value={dashboard.new}
        /> */}
        <div style={{ width: 450 }}>
          <CardCount
            borderColor="#D0D8DE"
            label="ACEITE PENDENTE"
            value={dashboard.pending}
          />
        </div>
        {/* <CardCount
          borderColor="#D90000"
          label="VIAGENS RECUSADAS"
          value={dashboard.refused}
        /> */}
      </div>
      <TableTrips
        followers={followers}
        following={following}
        pages={pages}
        handleDownload={handleDownload}
        handleSort={(dir, field) => {
          setSort(dir);
          setFieldSort(field);
        }}
        routes={routes}
        viewType="TABLE"
        page={page}
        handleCreateRoute={handleOpenModal}
        handleUpdateRoute={handleUpdateRoute}
        handleSearchValueChange={(text) => setSearchValue(text)}
        searchValue={searchValue}
        handleCancel={(id) => {
          setIdDelete(id);
          setShow(true);
        }}
        handlePage={(page) => {
          setPage(page);
        }}
      />
      <ConfirmationDialog
        show={show}
        title="Confime a Exclusão."
        content="Tem certeza que deseja excluir o item."
        onClickConfirm={handleCanceled}
        onClickCancel={() => setShow(false)}
        cancelButtonText="Cancelar"
        confirmButtonText={
          <>
            <span>Excluir</span>
          </>
        }
        useOverflow
      />
      <Dialog.AddTrip
        open={openModal}
        onClose={handleCloseModal}
        onClickSelectRoute={handleSelectTrip}
      />
    </PageContainer>
  );
};

export default TripsScreen;
