import { current } from "@reduxjs/toolkit";
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { Button, DatePicker, Notification } from "@rio-cloud/rio-uikit";

export const formatDateBr = (dateString: string, showHour = true) => {
  if (!dateString) return "";
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return showHour
    ? `${day}/${month}/${year} ${hours}:${minutes}`
    : `${day}/${month}/${year}`;
};

export const validarDate = (dateString: string, showHour = true) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const today = new Date();

  date.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  if (date < today) {
    Notification.error("A data selecionada tem que ser igual ou maior que a atual.");
    return false;
  }

  return true;
};

export const formatTimeBr = (
  date: Date,
  type?: "DEFAULT" | "HOUR" | "MINUTES" | "SECONDS" | "HOUR_AND_MINUTES"
) => {
  if (!date) return "";

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  switch (type) {
    case "HOUR":
      return hours;
    case "MINUTES":
      return minutes;
    case "SECONDS":
      return seconds;
    case "HOUR_AND_MINUTES":
      return `${hours}:${minutes}`;
    default:
      return `${hours}:${minutes}:${seconds}`;
  }
};

export const formatDateEn = (dateString?: string) => {
  if (!dateString) return "";

  const [day, month, year] = dateString.split('/');

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export const formatValueKmMask = (value: number) => {
  if (value === null || value === undefined) return '';

  const valueKM = value / 1000;

  const valueFormatted = valueKM.toFixed(0);

  return valueKM >= 1 ? `${valueFormatted}km` : `${valueKM * 1000}m`;
};