import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { main } from "./utils/configuration";
import { handleLoginRedirect } from "./utils/configuration/login/redirect";
import { config } from "./config";
import { Provider } from "react-redux";
import { store } from "@store";

const renderApplication = () => {
  createRoot(document.getElementById("root") as HTMLElement).render(<App />);
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
  handleLoginRedirect();
} else {
  main(renderApplication);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
