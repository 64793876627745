import { RegisterRouter, UpdateRouter } from "@components";
import { ApplicationLayout, Button, Sidebar } from "@rio-cloud/rio-uikit";
import { useAppSelector } from "@store";
import { IRoute, Route } from "@types";
import { IStop } from "../RegisterRoute";
import { useCallback } from "react";
import {CreateRouteCostPayload} from "../../types/costSimulator";

type TProps = {
  sendRoute: (
    origin: IRoute,
    destination: IRoute,
    routes: Route[],
    stops: IStop[],
    value: string,
    accountId: string,
    routeRangeToleranceLimit: number | null
  ) => void;
  handleRoutes: (routes: Route[], via: any[]) => void;
};

const UpdateSidebarRoute = ({ sendRoute, handleRoutes }: TProps) => {
  const { origin, destination, routes, stops, routeName, accountId, routeRangeToleranceLimit } =
    useAppSelector((state) => state.routes);

  const handleSave = (
    origin: IRoute,
    destination: IRoute,
    routes: Route[],
    stops: IStop[],
    routeName: string,
    routeCost: CreateRouteCostPayload,
    accountId: string,
    routeRangeToleranceLimit: number | null
  ) => {
      sendRoute(
        origin!,
        destination!,
        routes,
        stops,
        routeName,
        accountId,
        routeRangeToleranceLimit
      );
    };

  return (
    <ApplicationLayout.Sidebar className="right">
      <Sidebar
        title="Rota"
        closed={false}
        onClose={() => {}}
        position={Sidebar.RIGHT}
        disableClose
        width={500}
      >
        <div className="padding-left-20 padding-top-20 padding-right-20 padding-bottom-20">
          <RegisterRouter
            onChangeRoutes={handleRoutes}
            onSave={handleSave}
            saveLabel="Editar rota"
            // dataUpdateRoute={dataUpdateRoute}
            // setDataUpdateRoute={setDataUpdateRoute}
          />
          <>
            {/* <div className="btn-toolbar">
              <Button
                onClick={() =>
                  origin && destination
                    ? sendRoute(origin, destination, routes)
                    : null
                }
                block
              >
                Salvar rota e criar viagem
              </Button>
            </div> */}
          </>
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};

export default UpdateSidebarRoute;
