import { useState } from 'react';
import omit from 'lodash/fp/omit';
import isEmpty from 'lodash/fp/isEmpty';
import classNames from 'classnames';

import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles, { type TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import TableCardsSorting from '@rio-cloud/rio-uikit/TableCardsSorting';
import TableSettingsDialog from '@rio-cloud/rio-uikit/TableSettingsDialog';
import SortDirection, { type SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import NoData from '@rio-cloud/rio-uikit/NoData';
import { sortByProperty } from '@rio-cloud/rio-uikit/SortUtils';
import Button from '@rio-cloud/rio-uikit/Button';
import { deleteRoute } from "src/services/routes";
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Notification from '@rio-cloud/rio-uikit/Notification';

const defaultColumnOrder = ['vehicleId', 'name', 'vin', 'status'];
const disabledColumns = ['vin'];


type ColumnLabel = {
    [key: string]: string;
};
const columnLabels: ColumnLabel = {
    vehicleId: 'Id',
    name: 'Name',
    vin: 'VIN',
    status: 'Status',
};

type Vehicle = {
    vehicleId: number;
    name: React.ReactNode;
    vin: React.ReactNode;
    status: string;
};

const vehicleList: Vehicle[] = [
    {
        vehicleId: 7354,
        name: 'Lorem',
        vin: '8000',
        status: 'Active',
    },
    {
        vehicleId: 40000,
        name: 'Ipsum',
        vin: <NoData text='No data' />,
        status: 'Inactive',
    },
    {
        vehicleId: 895,
        name: <NoData text='No data' />,
        vin: '9000000',
        status: 'Active',
    },
];

const dummyActionOptions = [
    { value: 'Option 1', onSelect: () => { } },
    { value: 'Option 2', onSelect: () => { } },
    { value: 'Option 3', onSelect: () => { } },
    { divider: true },
    { value: 'Option 4', onSelect: () => { }, disabled: true },
];

const getSortDir = (sortDir: SortDirectionType, sortBy: string, previousSortBy: string) => {
    if (sortBy === previousSortBy) {
        return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
};

export type TableCommonDemoProps = {
    viewType: TableViewTogglesViewType;
};

const TableOnGoing = (props: TableCommonDemoProps) => {
    const { viewType: externalViewType } = props;

    const [searchValue, setSearchValue] = useState('');
    const [sortBy, setSortBy] = useState('vehicleId');
    const [sortDir, setSortDir] = useState<SortDirectionType>(SortDirection.ASCENDING);
    const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
    const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [viewType, setViewType] = useState(externalViewType || TableViewToggles.VIEW_TYPE_TABLE);

    const handleToggleTableSettingsDialog = () => setShowTableSettingsDialog(!showTableSettingsDialog);
    const handleViewTypeChange = (newViewType: TableViewTogglesViewType) => setViewType(newViewType);
    const handleSearchValueChange = (newSearchValue: string) => setSearchValue(newSearchValue);

    const handleColumnOrderChange = (newColumnOrder: string[], newHiddenColumns: string[]) => {
        setColumnOrder(newColumnOrder);
        setHiddenColumns(newHiddenColumns);
    };

    const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {
        const newSortBy = event.currentTarget.getAttribute('data-sortby');
        if (newSortBy) {
            handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
        }
    };

    const handleCardSortChange = (newSortBy: string, newSortDir: SortDirectionType) => {
        setSortBy(newSortBy);
        setSortDir(newSortDir);
    };

    // May be extracted as a dedicated component but for demo purpose it's shown here
    const renderTableHead = (column: string, label: string, sortByColumn: string, sortDirection: SortDirectionType) => {
        const tableHeadClassNames = classNames('user-select-none', 'sort-column');

        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={handleSortChange}
                data-field={column}
                data-sortby={column}
                title={label}
            >
                <span>
                    {sortByColumn === column ? <SortArrows direction={sortDirection} /> : <SortArrows />}
                    <span>{label}</span>
                </span>
            </th>
        );
    };

    // filter for hidden columns
    const columns = columnOrder.filter(name => !hiddenColumns.includes(name));

    // filter data to omit hidden columns
    const withoutHidden = omit(hiddenColumns);
    const filteredRows = vehicleList.map(vehicle => ({ ...withoutHidden(vehicle) })) as Vehicle[];

    // in case a search value is given, filter the data accordingly
    const searchResult = !searchValue
        ? filteredRows
        : filteredRows.filter((row: Partial<Vehicle>) =>
            columns.some(col => {
                const value = row[col as keyof Vehicle];
                if (value == null) {
                    return false;
                }
                row[col as keyof Vehicle]?.toString().toLowerCase().includes(searchValue.toLowerCase());
            })
        );

    // Sort rows according to the sortBy and sortDir settings
    const rows = sortBy ? sortByProperty(searchResult, sortBy as keyof Vehicle, sortDir) : searchResult;

    const tableClassNames = classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
        viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards'
    );

    const isViewTypeTable = viewType === TableViewToggles.VIEW_TYPE_TABLE;

    const cardSortingSelectOptions = columns.map(column => {
        return {
            id: column,
            label: columnLabels[column],
            selected: column === sortBy,
            disabled: false,
        };
    });

    return (
        <div id='TableCommonDemo'>
            <TableToolbar>
                <div className='table-toolbar-container'>
                    <div className='table-toolbar-group-right'>
                        <div className='table-toolbar-column table-toolbar-column-spacer'>
                            <label className='table-toolbar-label'>Label</label>
                            <TableViewToggles
                                initialViewType={viewType}
                                onViewTypeChange={handleViewTypeChange}
                                tableViewTooltipContent='Table view'
                                singleCardViewTooltipContent='List view'
                                multiCardsViewTooltipContent='Cards view'
                            />
                        </div>
                        <div className='table-toolbar-column'>
                            <Button iconOnly iconName='rioglyph-settings' onClick={handleToggleTableSettingsDialog} />
                        </div>
                    </div>
                    {showTableSettingsDialog && (
                        <TableSettingsDialog
                            show={showTableSettingsDialog}
                            title='Table Settings'
                            onHide={handleToggleTableSettingsDialog}
                            onColumnChange={handleColumnOrderChange}
                            defaultColumnOrder={defaultColumnOrder}
                            defaultHiddenColumns={[]}
                            columnOrder={columnOrder}
                            hiddenColumns={hiddenColumns}
                            columnLabels={columnLabels}
                            disabledColumns={disabledColumns}
                            closeButtonText='Close'
                            cancelButtonText='Cancel'
                            applyButtonText='Apply changes'
                            resetButtonText='Reset to default'
                            searchPlaceholder='Search by column name'
                            notFoundMessage='No column found for this search value'
                            autoLabel='Auto'
                        />
                    )}
                </div>
            </TableToolbar>
            {sortBy && !isViewTypeTable && (
                <TableCardsSorting
                    selectOptions={cardSortingSelectOptions}
                    sortName={sortBy}
                    sortOrder={sortDir}
                    onSortChange={handleCardSortChange}
                />
            )}
            <div>
                {isEmpty(rows) && !isViewTypeTable && (
                    <NotFoundState headline='Nothing found' message='Please refine your search' />
                )}
                <table className={tableClassNames}>
                    <colgroup>
                        {columns.map(column => (
                            <col key={column} />
                        ))}
                        <col className='table-action' />
                    </colgroup>
                    <thead>
                        <tr>
                            {columns.map(column => renderTableHead(column, columnLabels[column], sortBy, sortDir))}
                            <th className='table-action' />
                        </tr>
                    </thead>
                    <tbody>
                        {isEmpty(rows) && isViewTypeTable && (
                            <tr>
                                <td colSpan={columns.length + 1}>
                                    <NotFoundState
                                        outerClassName='border-none'
                                        headline='Nothing found'
                                        message='Please refine your search'
                                    />
                                </td>
                            </tr>
                        )}
                        {rows.map((row: Vehicle, index: number) => (
                            <tr key={index}>
                                {columns.map(col => (
                                    <td key={col} data-field={columnLabels[col]}>
                                        <span>{row[col as keyof Vehicle]}</span>
                                    </td>
                                ))}
                                <td className='table-action'>
                                    <DummyRowDropdown />
                                </td>
                            </tr>
                        ))}
                        {/* Placeholder workaround for equal with cards of the last row */}
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                    </tbody>
                </table>
            </div>
        </div>
    );
};


const DummyRowDropdown = () => {

    const [show, setShow] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const handleDeleteClick = (id: number) => {
        setDeleteId(deleteId);
        setShow(true);
    };

    const deleteApi = async (id: number | null) => {
        if (id !== null) {
            try {
                const response = await deleteRoute(id);
                if (response.status === 200 || response.status === 204) {
                    Notification.info('Rota excluída com sucesso')
                } else {
                    Notification.info('Erro ao excluir o rota')
                }
            } catch (error) {
                console.error('Erro ao conectar à API:', error);
            }
        }
        setShow(false);
    };

    return (
        <span>
            <ButtonDropdown
                title={<span className='rioglyph rioglyph-option-vertical' />}
                variant='link'
                iconOnly
                items={[
                    {
                        value: (
                            <div>
                                <span className='rioglyph rioglyph-pencil margin-right-10' />
                                <span>Edit</span>
                            </div>
                        ),
                    },
                    {
                        value: (
                            <div>
                                <span className='rioglyph rioglyph-duplicate margin-right-10' />
                                <span>Duplicate</span>
                            </div>
                        ),
                    },
                    {
                        value: (
                            <div onClick={() => handleDeleteClick(1)}>
                                <span className='rioglyph rioglyph-trash margin-right-10' />
                                <span>Delete</span>
                            </div>
                        ),
                    },
                ]}
            />

            {show && (
                <ConfirmationDialog
                    show={show}
                    title={<><h3>Excluir rotas</h3></>}
                    content={<>Ao excluir a rota, não irá gerar impacto nas viagens agendadas, em andamento ou finalizadas. < br /><br />Confirma a exclusão da rota?</>}
                    bsSize='sm'
                    onClickConfirm={() => deleteApi(1)}
                    onClickCancel={() => setShow(false)}
                    cancelButtonText='Cancelar'
                    confirmButtonText={
                        <>
                            <span className='rioglyph rioglyph-ok margin-right-5' />
                            <span>Confirmar</span>
                        </>
                    }
                    useOverflow
                />
            )}
        </span>
    );
};



export default TableOnGoing;