import api from ".";
import {
    CalculateFuelCostResponse,
    CreateRouteCostPayload,
    getByIdRouteCostResponse,
    getCalculateTollResponse,
    getCostTypeByAccountResponse,
    getCurrencyTypesResponse,
    getTruckTypesResponse
} from "../types/costSimulator";

export const getCostTypeByAccount = async (accountId?: string): Promise<getCostTypeByAccountResponse[]> => {
    const response = await api.get(`/v1/cost-types/account/${accountId}`, {});
    return response.data;
}

export const getTruckTypes = async (): Promise<getTruckTypesResponse[]> => {
    const response = await api.get(`/v1/truck-types/listAll`, {});
    return response.data;
}

export const getCalculateToll = async (
    lats?: string[],
    longs?: string[],
    truckType?: number
): Promise<getCalculateTollResponse> => {
    const latsQuery = lats ? `lats=${lats.join(",")}` : "";
    const longsQuery = longs ? `longs=${longs.join(",")}` : "";
    const truckTypeQuery = truckType ? `truckType=${truckType}` : "";

    const query = [latsQuery, longsQuery, truckTypeQuery]
        .filter(Boolean)
        .join("&");

    const response = await api.get(`/v1/calculate/calculateToll?${query}`, {});
    return response.data;
};

export const getCalculateFuelCost = async (
    costType: number,
    consumptionPerKm: number,
    priceByCostType: number,
    distanceInMeters: number
): Promise<CalculateFuelCostResponse> => {
    const query = [
        `costType=${costType}`,
        `consumptionPerKm=${consumptionPerKm}`,
        `priceByCostType=${priceByCostType}`,
        `distanceInMeters=${distanceInMeters}`,
    ].join("&");

    const response = await api.get(`/v1/calculate/calculateFuelCost?${query}`, {});
    return response.data as CalculateFuelCostResponse;
};

export const createRouteCost = async  (request: CreateRouteCostPayload): Promise<getByIdRouteCostResponse> => {
    const response = await api.post(`/v1/route-costs/`, request);
    return response.data;
};

export const getByIdRouteCost = async (routeId?: string): Promise<getByIdRouteCostResponse> => {
    const response = await api.get(`/v1/route-costs/${routeId}`, {});
    return response.data
};

export const getCurrencyTypes = async (): Promise<getCurrencyTypesResponse[]> => {
    const response = await api.get(`v1/currency-types/`, {});
    return response.data;
}

