import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSidebar } from "src/store/sidebar/SidebarSlice";
import { decode } from "src/utils/flexiblePolyline";
import {
  Coordinates,
  getBoundingBox,
  getRandomValueToForceRerender,
  RoutePoint,
  SandboxMarker,
} from "src/components/Map";
import { RioMap } from "@components";
import Notification from "@rio-cloud/rio-uikit/Notification";
import { IResponseFetchDetail, Route } from "src/types/routes";
import UpdateSidebarRoute from "src/components/UpdateSidebarRoute";
import { fetchDetail, updateRoute } from "src/services/routes";
import {
  defineAccountId,
  defineDestination,
  defineOrigin,
  defineRouteDeviation,
  defineRouteName,
  defineRoutes,
  defineStops,
} from "src/store/routes/RoutesSlice";
import { IRoute } from "@types";
import { IStop } from "src/components/RegisterRoute";
import { AxiosError } from "axios";
import { useAppSelector } from "@store";
import { ApplicationLayout } from "@rio-cloud/rio-uikit";
import { useNavigate, useSearchParams } from "react-router-dom";

const RoutesUpdateScreen = () => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState<Coordinates | undefined>({
    lat: -23.5475,
    lng: -46.63611,
  });
  const [boundingBox, setBoundingBox] = useState<
    { top: number; left: number; right: number; bottom: number } | undefined
  >();
  const [segments, setSegments] = useState<RoutePoint[]>([]);
  const [markers, setMarkers] = useState<SandboxMarker[]>([]);

  const [search] = useSearchParams();

  const getRouteIdAndTokenFromURL = () => {
    const params = new URLSearchParams(search);
    const routeId = params.get("id");
    if (!routeId) {
      Notification.error("ID da rota ou token ausente na URL.");
      throw new Error("ID ou token ausente na URL.");
    }
    return { routeId };
  };

  useEffect(() => {
    dispatch(defineOrigin(null));
    dispatch(defineDestination(null));
    dispatch(defineRoutes([]));
    dispatch(defineStops([]));
    dispatch(defineRouteName(""));
    dispatch(defineAccountId(""));
  }, []);

  useEffect(() => {
    return () => {
        dispatch(defineRouteDeviation(0));
    }
  }, []);

  const fetchRouteDetails = async () => {
    try {
      const { routeId } = getRouteIdAndTokenFromURL();
      const response = await fetchDetail(routeId);
      populateMap(response);
    } catch (error) {
      Notification.error("Não foi possível carregar os detalhes da rota.");
    }
  };

  const handleRoutes = (routes: Route[], via: any[]) => {
    if (routes.length) {
      let ArrayOfShape: any[] = [];
      const route = routes?.[0];
      const data = route?.sections || [];

      const getPolylineArray = data.map((item) => {
        return {
          shape: decode(item.polyline),
        };
      });

      const markers = data.reduce((acum: SandboxMarker[], curr, index, arr) => {
        if (index === 0) {
          const markerOrigin: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["start"],
              name: "",
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.departure.place.location.lat,
              lng: curr.departure.place.location.lng,
            },
          };
          acum.push(markerOrigin);
        }
        if (index === arr.length - 1) {
          const markerDestination: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["finish"],
              name: "",
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.arrival.place.location.lat,
              lng: curr.arrival.place.location.lng,
            },
          };
          acum.push(markerDestination);
        } else {
          const markerStopover: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["rioglyph rioglyph-pushpin"],
              name: via?.[index]?.type,
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.arrival.place.location.lat,
              lng: curr.arrival.place.location.lng,
            },
          };
          acum.push(markerStopover);
        }

        return acum;
      }, []);

      getPolylineArray.forEach((item) => {
        ArrayOfShape.push(item.shape.polyline);
      });
      const concatArrays = ArrayOfShape.flat();
      const getPoints = concatArrays.map((value) => {
        return {
          lat: value[0].toString(),
          lng: value[1].toString(),
        };
      });
      setSegments(getPoints as RoutePoint[]);
      setMarkers(markers);
      if (getPoints?.length > 0) {
        setPosition(undefined);
        setBoundingBox(getBoundingBox(getPoints));
      } else if (markers?.length > 0) {
        setPosition(markers[0].position);
      }
    } else {
      setMarkers([]);
      setSegments([]);
    }
  };

  const populateMap = ({ data }: IResponseFetchDetail) => {
    dispatch(
      defineOrigin({
        id: data.result.originRoute.id,
        label: data.result.originRoute.address.label,
        lng: data.result.originRoute.long,
        lat: data.result.originRoute.lat,
      })
    );
    dispatch(
      defineDestination({
        id: data.result.destinyRoute.id,
        label: data.result.destinyRoute.address.label,
        lng: data.result.destinyRoute.long,
        lat: data.result.destinyRoute.lat,
      })
    );
    dispatch(defineRoutes(data.result.responseHere.routes));
    const stops = data.result.stops.map((stop) => {
      return {
        id: stop.id,
        category: stop.stop.category,
        order: stop.stopQueueOrder,
        stayTime: (stop.stayTime as string).split("T")[1].substring(0, 5),
        position: {
          lat: stop.stop.position.lat,
          lng: stop.stop.position.long,
          label: stop.stop.position.address.label,
        },
      };
    });
    dispatch(defineRouteDeviation(data.result.rangeToleranceLimit))
    dispatch(defineStops(stops));
    dispatch(defineRouteName(data.result.routeName));
    dispatch(defineAccountId(data.result.accountId));
    handleRoutes(data.result.responseHere.routes, stops);
  };

  const formatCategory = (text: string) => {
    const DICT = {
      LUNCH_REST: "Refeição",
      LOAD_UNLOAD: "Coleta de carga",
      END_OF_DAY: "Fim de jornada de trabalho",
      DEALER: "Concessionária",
    };

    return DICT[text as "LUNCH_REST" | "LOAD_UNLOAD" | "END_OF_DAY" | "DEALER"];
  };

  const sendRoute = async (
    origin: IRoute,
    destination: IRoute,
    routes: Route[],
    stops: IStop[],
    value: string,
    accountId: string,
    routeDeviation: number | null
  ) => {
    const { routeId } = getRouteIdAndTokenFromURL();
    const body = {
      id: routeId,
      accountId,
      routeName: value,
      originRoute: {
        id: origin.id,
        address: {
          label: origin.label,
        },
        addressStop: {
          label: origin.label,
        },
        lat: origin.lat,
        lng: origin.lng,
      },
      destinyRoute: {
        id: destination.id,
        address: {
          label: destination.label,
        },
        addressStop: {
          label: destination.label,
        },
        lat: destination.lat,
        lng: destination.lng,
      },
      rangeToleranceLimit: routeDeviation,
      stops: stops.map((stop, index) => ({
        id: stop.id,
        name: `Parada ${index + 1}`,
        category: stop.category,
        rangeLimitMeters: "0",
        position: {
          lat: stop.position?.lat,
          lng: stop.position?.lng,
          addressStop: {
            label: stop?.position?.label || "",
          },
        },
        stayTime: `${stop.stayTime}:00`,
        note: formatCategory(stop.category),
        type: "STOPOVER",
        order: stop.order,
      })),
      roadParameters: {
        trafficConditions: "false",
        avoidToll: "false",
        avoidRoad: false,
        ignoreTrafficRestrictions: false,
      },
      vehicleVocationalInfo: {
        type: "TRUCK",
      },
      costs: {
        tollValue: "0",
        operativeCosts: "0",
        fuelAverageCosts: "0",
        averageConsume: "0",
        totalCosts: "0.00",
      },
      responsibleId: "felipy.cabral@opah.com.br",
      status: true,
      linkedVehicles: [],
      responseHere: { routes },
    };
    try {
      const response = await updateRoute(body);
      Notification.info(response.data.message);
      setTimeout(() => {
        navigate("/routing/routes");
      }, 1000);
    } catch (error) {
      type TError = { message: string };
      const message =
        typeof error === "string"
          ? error
          : (error as AxiosError<TError>)?.response?.data?.message ||
            "Erro desconhecido";
      Notification.error(message as string);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchRouteDetails();
  }, []);

  return (
    <>
      <UpdateSidebarRoute handleRoutes={handleRoutes} sendRoute={sendRoute} />
      <ApplicationLayout.Body innerClassName="padding-0">
        <RioMap
          position={position}
          boundingBox={boundingBox}
          apiKey={process.env.REACT_APP_RIO_MAP_KEY as string}
          segments={
            segments.length ? [{ points: segments, alternative: false }] : []
          }
          markers={markers}
        />
      </ApplicationLayout.Body>
    </>
  );
};

export default RoutesUpdateScreen;
