import { ISendTripRequest, IDeletedResquest} from "@types";
import api from ".";

export const fetchTripsList = async (page: number, size: number, search?: string, sortB?: string, fieldSort?: string) => {
    const params = new URLSearchParams();
    params.append("size", size.toString());
    params.append("page", page.toString());
  
    const names = {
      name: 'driverName',
      origin: 'originName',
      destination: 'destinationName',
      stops: 'quantityStops',
      distance: 'distance',
      duration: 'duration',
      vehicle: 'assetName'
    }
    
    if (search) {
      params.append("search", search);
    }
  
    if (sortB) {
      params.append('order', sortB.toUpperCase());
    }
  
    if (fieldSort && fieldSort in names) {
      params.append('orderBy', names[fieldSort as keyof typeof names]);
    }
  
    const response = await api.get(`v2/routes/trips?${params.toString()}`);
    return response.data;
  };


export const sendTrip = async (body: ISendTripRequest, routeId: string) => {
  const response = await api.post(`v2/routes/rio/send/${routeId}`, body);
  return response.data;
};

export const fetchCanceledTrip = async (body: IDeletedResquest, id: string) => {
  const response = await api.put(`v2/routes/trips/${id}`, body);
  return response.data;
};