import { RegisterRouter } from "@components";
import { useState } from "react";
import { ApplicationLayout, Button, Sidebar } from "@rio-cloud/rio-uikit";
import { useAppSelector } from "@store";
import { IRoute, Route } from "@types";
import { IStop } from "../RegisterRoute";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import {CreateRouteCostPayload} from "../../types/costSimulator";

type TProps = {
  sendRoute: (
    origin: IRoute,
    destination: IRoute,
    routes: Route[],
    stops: IStop[],
    routeCost: CreateRouteCostPayload,
    value: string,
    routeRangeToleranceLimit: number | null
  ) => void;
  handleRoutes: (routes: Route[], via: any[]) => void;
};

const SidebarRoute = ({ sendRoute, handleRoutes }: TProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [value, setValue] = useState("");
  const { origin, destination, routes, stops, routeCost, routeRangeToleranceLimit } = useAppSelector(
    (state) => state.routes
  );

  const handleClick = () => {
    if (origin && destination) {
      setShowDialog(true);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendRoute(origin!, destination!, routes, stops, routeCost, value, routeRangeToleranceLimit);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
    setEnableSubmit(Boolean(event.currentTarget.value));
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <ApplicationLayout.Sidebar className="right">
      <Sidebar
        title="Rota"
        closed={false}
        fly
        onClose={() => {}}
        position={Sidebar.RIGHT}
        disableClose
        makeBackdropVisible={false}
        width={600}
      >
        <div className="padding-left-20 padding-top-20 padding-right-20">
          <RegisterRouter
            onChangeRoutes={handleRoutes}
            onSave={handleClick}
          />
          <>
            {/* <div className="btn-toolbar">
              <Button
                disabled={!origin || !destination}
                onClick={handleClick}
                block
                bsStyle={Button.PRIMARY}
              >
                Salvar rota
              </Button>
            </div>
            <br />
            <div className="btn-toolbar">
              {/*<Button
                disabled={!origin || !destination}
                // onClick={() =>
                //   origin && destination
                //</div>     ? sendRoute(origin, destination, routes, value)
                //     : null
                //}
                block
              >
                Salvar rota e criar viagem
              </Button>}
            </div> */}
            <Dialog
              show={showDialog}
              onClose={handleClose}
              showCloseButton
              bsSize="md"
            >
              <form onSubmit={handleSubmit}>
                <Dialog.Title
                  title={""}
                  subtitle="Salvar Rota"
                  headerButtons={
                    <Button
                      bsStyle="muted"
                      iconOnly
                      iconName="rioglyph-cloud-download"
                    />
                  }
                />
                <Dialog.Body className="foo">
                  <div>
                    <div className="form-group">
                      <label htmlFor="demoInput">Nome da Rota</label>
                      <div className="webfont-preview display-flex align-items-center margin-top-10 margin-bottom-10">
                        <span className="rioglyph rioglyph-route-option text-size-20 margin-right-10"></span>

                        <input
                          className="form-control"
                          name="demoInput"
                          placeholder=""
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Body>
                <Dialog.Footer className="foobar">
                  <div className="display-flex gap-10 justify-content-end">
                    <Button
                      bsStyle="default"
                      type="button"
                      onClick={handleClose}
                      disabled={!enableSubmit}
                    >
                      Cancelar
                    </Button>
                    <Button
                      bsStyle="primary"
                      type="submit"
                      disabled={!enableSubmit}
                    >
                      Salvar
                    </Button>
                  </div>
                </Dialog.Footer>
              </form>
            </Dialog>
          </>
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};

export default SidebarRoute;
