export const asBool = (value: string | undefined): boolean => value === "true";

export interface ConfigState {
  backend: {
    AUTHENTICATION_SERVICE: string | undefined;
    MENU_SERVICE: string | undefined;
  };
  homeRoute: string | undefined;
  id: string | undefined;
  login: {
    authority: string | undefined;
    clientId: string | undefined;
    oauthScope: string[];
    mockAuthorization: boolean;
    mockLocale: string | undefined;
    mockTenant: string | undefined;
    preventRedirect: boolean;
    redirectUri: string | undefined;
    silentRedirectUri: string | undefined;
  };
  serviceEnvironment: string;
  enableMockServer: boolean;
  logoutUri: string | undefined;
}

export const config: ConfigState = {
  backend: {
    AUTHENTICATION_SERVICE: process.env.REACT_APP_AUTHENTICATION_SERVICE,
    MENU_SERVICE: process.env.REACT_APP_MENU_SERVICE,
  },
  homeRoute: process.env.REACT_APP_HOME_ROUTE,
  id: process.env.REACT_APP_ID,
  login: {
    authority: process.env.REACT_APP_LOGIN_AUTHORITY,
    clientId: process.env.REACT_APP_LOGIN_CLIENT_ID,
    oauthScope: ["openid", "profile", "email"],
    mockAuthorization:
      process.env.NODE_ENV === "development"
        ? true
        : !!process.env.REACT_APP_DEV,
    mockLocale: process.env.REACT_APP_LOGIN_MOCK_LOCALE,
    mockTenant: process.env.REACT_APP_LOGIN_MOCK_TENANT,
    preventRedirect: asBool(process.env.REACT_APP_LOGIN_PREVENT_REDIRECT),
    redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI,
    silentRedirectUri: process.env.REACT_APP_LOGIN_SILENT_REDIRECT_URI,
  },
  serviceEnvironment: process.env.MODE!,
  enableMockServer:
    process.env.NODE_ENV === "development" ? true : !!process.env.REACT_APP_DEV,
  logoutUri: process.env.REACT_APP_LOGOUT_URI,
};
