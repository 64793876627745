import {ChangeEvent, useEffect, useRef, useState} from "react";
import {debounce} from "lodash";
import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel";
import Button from "@rio-cloud/rio-uikit/Button";
import "./routes.css";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import DataTab from "@rio-cloud/rio-uikit/DataTab";
import DataTabs from "@rio-cloud/rio-uikit/DataTabs";
import Autosuggest from "../Autosuggest";
import {fetchAutosuggest, mountRoute} from "src/services/routes";
import {AutoSuggestSuggestion} from "@rio-cloud/rio-uikit/components/autosuggest/AutoSuggest";
import moment from "moment";
import {IRoute, Route} from "@types";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import {useDispatch} from "react-redux";
import {
    defineDestination,
    defineOrigin,
    defineRouteDeviation,
    defineRoutes,
    defineStops,
    defineRouteCost
} from "src/store/routes/RoutesSlice";
import {useAppSelector} from "@store";
import CustomSlider from "../CustomSlider/CustomSlider";
import CustomInputSelect from "../CustomInputSelect/CustomInputSelect";
import {
    getCalculateFuelCost,
    getCalculateToll,
    getCostTypeByAccount,
    getTruckTypes,
    getCurrencyTypes
} from "../../services/costSimulator";
import {CreateRouteCostPayload} from "../../types/costSimulator";

interface DynamicContentSimuiladorProps {
    distance: string;
    selectedFuelOption: string | number;
    setSelectedFuelOption: (value: string | number) => void;
    selectedVehicleOption: string | number;
    setSelectedVehicleOption: (value: string | number) => void;
    kmInputValue: string;
    setKmInputValue: (value: string) => void;
    priceInputValue: string;
    setPriceInputValue: (value: string) => void;
    fuelType: { value: number; label: string }[];
    setFuelType: (value: { value: number; label: string }[]) => void;
    vehicleType: { value: number; label: string }[];
    setVehicleType: (value: { value: number; label: string }[]) => void;
    tollValue: number;
    setTollValue: (value: number) => void;
    fuelValue: number;
    setFuelValue: (value: number) => void;
    totalValue: number;
    setTotalValue: (value: number) => void;
    showResults: boolean;
    setShowResults: (value: boolean) => void;
    origin: any;
    destination: any;
    stops: IStop[];
}

const DynamicContentSimuilador = ({
                                      distance,
                                      selectedFuelOption,
                                      setSelectedFuelOption,
                                      selectedVehicleOption,
                                      setSelectedVehicleOption,
                                      kmInputValue,
                                      setKmInputValue,
                                      priceInputValue,
                                      setPriceInputValue,
                                      fuelType,
                                      setFuelType,
                                      vehicleType,
                                      setVehicleType,
                                      tollValue,
                                      setTollValue,
                                      fuelValue,
                                      setFuelValue,
                                      totalValue,
                                      setTotalValue,
                                      showResults,
                                      setShowResults,
                                      origin,
                                      destination,
                                      stops
                                  }: DynamicContentSimuiladorProps) => {

    const priceInputRef = useRef<HTMLInputElement>(null);

    const {
        accountId,
    } = useAppSelector((state) => state.routes);

    useEffect(() => {
        fetchFuelTypes();
        fetchVehicleTypes();
    }, [accountId]);

    useEffect(() => {
        if (kmInputValue) {
            const numericValue = kmInputValue
                .replace(/\s?km\/litro|\s?km\/kwh/g, "")
                .trim();

            const selectedFuel = fuelType.find(fuel => fuel.value == selectedFuelOption);
            const updatedValue = `${numericValue} ${
                selectedFuel?.label.toLowerCase() === "elétrico" ? "km/kwh" : "km/litro"
            }`;
            setKmInputValue(updatedValue);
        }
    }, [selectedFuelOption]);

    const fetchFuelTypes = async () => {
        try {
            const response = await getCostTypeByAccount(accountId);
            const formattedFuelTypes = response.map((item: any) => ({
                value: item.id,
                label: item.name,
            })).sort((a, b) => a.label.localeCompare(b.label));

            setFuelType(formattedFuelTypes);

            const combustivel = formattedFuelTypes.find(fuel => fuel.label.toLowerCase() === "combustível");
            if (combustivel) {
                setSelectedFuelOption(combustivel.value);
            }
        } catch (error) {
            console.error("Error fetching cost types by account id:", error);
        }
    };

    const fetchVehicleTypes = async () => {
        try {
            const response = await getTruckTypes();
            const formattedVehicleTypes = response.map((item: any) => ({
                value: item.id,
                label: item.name,
            }));
            setVehicleType(formattedVehicleTypes);
        } catch (error) {
            console.error("Error fetching vehicle types:", error);
        }
    };

    useEffect(() => {
        const fetchTools = async () => {
            if (origin && destination && showResults) {
                await fetchCalculateToll();
            }
        }
        fetchTools();
    }, [selectedVehicleOption, origin, destination, stops]);

    useEffect(() => {
        const fetchFuel = async () => {
            if (kmInputValue && priceInputValue && showResults) {
                try {
                    await fetchCalculateFuelCost();
                } catch (error) {
                    console.error("Error fetching fuel cost:", error);
                }
            }
        };

        const debouncedFetchFuel = debounce(fetchFuel, 500);

        debouncedFetchFuel();

        return () => {
            debouncedFetchFuel.cancel();
        };
    }, [selectedFuelOption, kmInputValue, priceInputValue, distance]);

    const calculateCost = async () => {
        if (kmInputValue && priceInputValue) {
            try {
                await Promise.all([fetchCalculateToll(), fetchCalculateFuelCost()]);
                setShowResults(true);
            } catch (error) {
                console.error("Error calculating costs:", error);
            }
        }
    };

    const fetchCalculateToll = async (): Promise<void> => {
        try {
            const numericVehicleOption = typeof selectedVehicleOption === "string" ? parseInt(selectedVehicleOption) : selectedVehicleOption;
            const lats = [origin?.lat, ...stops.map((stop) => stop.position.lat), destination?.lat];
            const lngs = [origin?.lng, ...stops.map((stop) => stop.position.lng), destination?.lng];
            const response = await getCalculateToll(lats, lngs, numericVehicleOption);

            setTollValue(response.data.result.tollCost);
        } catch (error) {
            console.error("Error fetching toll cost:", error);
            setTollValue(0);
        }
    };

    const fetchCalculateFuelCost = async (): Promise<void> => {
        try {
            const numericFuelOption = typeof selectedFuelOption === "string" ? parseInt(selectedFuelOption) : selectedFuelOption;
            const priceMatch = priceInputValue.match(/\d+(\.\d+)?/g);
            const parsedPriceInputValue = priceMatch ? parseFloat(priceMatch.join(".")) : 0;

            if (isNaN(parsedPriceInputValue)) {
                throw new Error("Invalid price input value");
            }

            const distanceValue = convertKmToMeters(parseFloat(distance));
            const response = await getCalculateFuelCost(
                numericFuelOption,
                parseFloat(kmInputValue),
                parsedPriceInputValue,
                distanceValue
            );

            setFuelValue(response.data.result.value);
        } catch (error) {
            console.error("Error fetching fuel cost:", error);
            setFuelValue(0);
        }
    };

    useEffect(() => {
        if (tollValue !== null && fuelValue !== null) {
            setTotalValue(tollValue + fuelValue);
        }
    }, [tollValue, fuelValue]);

    const convertKmToMeters = (distanceInKm: number): number => {
        return distanceInKm * 1000;
    };

    const handleVehicleOptionChange = (newValue: string | number) => {
        setSelectedVehicleOption(newValue);
    };

    const handleFuelOptionChange = (newValue: string | number) => {
        setSelectedFuelOption(newValue);
    };

    const handleKmInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let rawValue = e.target.value.replace(/[^0-9.,]/g, "");
        rawValue = rawValue.replace(",", ".");
        setKmInputValue(rawValue);
    };

    const handleKmInputBlur = () => {
        if (!kmInputValue) return;

        const selectedFuel = fuelType.find(fuel => fuel.value == selectedFuelOption);
        const formattedValue = `${parseFloat(kmInputValue).toFixed(2)} ${
            selectedFuel?.label.toLowerCase() === "elétrico" ? "km/kwh" : "km/litro"
        }`;

        setKmInputValue(formattedValue);
    };

    const handleKmInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Tab") {
            e.preventDefault();
            priceInputRef.current?.focus();
        }
    };

    const handlePriceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let rawValue = e.target.value.replace(/[^0-9,]/g, "");
        setPriceInputValue(rawValue);
    };

    const handlePriceInputBlur = () => {
        if (!priceInputValue) return;

        const numericValue = priceInputValue.replace(",", ".");
        if (!isNaN(Number(numericValue))) {
            const formattedValue = `R$ ${Number(numericValue)
                .toFixed(2)
                .replace(".", ",")}`;
            setPriceInputValue(formattedValue);
        }
    };

    const formatCurrency = (value: number | null | undefined): string => {
        if (value === null || value === undefined || isNaN(value)) {
            return "R$ 0,00";
        }
        return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
    };

    return (
        <div className="col-12">
            <div className="simulador-calc-main-block">
                <div className="simulador-block-01">
                    <label htmlFor="inputWithMask1">Tipo de Alimentação</label>
                    <CustomInputSelect options={fuelType}
                                       value={selectedFuelOption}
                                       onChange={handleFuelOptionChange}/>
                    <div className="simulador-form-group1">
                        <label htmlFor="inputWithMask1">Consumo veículo (
                            {selectedFuelOption === 1 ? "km/litro" : "km/kwh"})</label>
                        <input
                            type="text"
                            className="simulador-input-1"
                            value={kmInputValue}
                            onChange={handleKmInputChange}
                            onBlur={handleKmInputBlur}
                            onKeyDown={handleKmInputKeyDown}
                        />
                    </div>
                </div>
                <div className="simulador-block-02">
                    <label htmlFor="inputWithMask1">Tipo de veículo</label>
                    <CustomInputSelect options={vehicleType}
                                       value={selectedVehicleOption}
                                       onChange={handleVehicleOptionChange}/>
                    <div className="simulador-form-group2">
                        <label htmlFor="inputWithMask1">Preço combustível (R$/litro)</label>
                        <input
                            type="text"
                            className="simulador-input-2"
                            value={priceInputValue}
                            onChange={handlePriceInputChange}
                            onBlur={handlePriceInputBlur}
                            placeholder=""
                            ref={priceInputRef}
                        />
                    </div>
                </div>
            </div>
            <div className="display-flex flex-wrap simulador-item-container">
                <Button className="botao-com-calcular" onClick={calculateCost}>Calcular</Button>
            </div>
            {showResults && (
                <div className="col-12 simulador-result-info-container">
                    <div className="simulador-result-item">
                        <div>
                            <label className="simulador-item-info-result-title">Custo combustível</label>
                        </div>
                        <label className="simulador-item-info-result-value">
                            {formatCurrency(fuelValue)}
                        </label>
                    </div>
                    <div className="simulador-result-item">
                        <div>
                            <label className="simulador-item-info-result-title">Custo pedágio</label>
                        </div>
                        <label className="simulador-item-info-result-value">
                            {formatCurrency(tollValue)}
                        </label>
                    </div>
                    <div className="simulador-result-item-total">
                        <div>
                            <label className="simulador-item-info-result-total">TOTAL</label>
                        </div>
                        <label className="simulador-item-info-result-total-value">
                            {formatCurrency(totalValue)}
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

interface DynamicContentDesvioProps {
    isChecked: boolean
    rangeValue: number
    onCheckedChange: (e: ChangeEvent<HTMLInputElement>) => void
    onDeviationChange: (value: number) => void
}

const DynamicContentDesvio = ({
                                  isChecked,
                                  onCheckedChange,
                                  rangeValue,
                                  onDeviationChange,
                              }: DynamicContentDesvioProps) => {
    const formatValueWithUnit = (value: number): string =>
        `${value.toFixed(2).replace(".", ",")} km`;

    const parseValueFromUnit = (value: string): number => {
        const numericValue = parseFloat(value.replace(",", ".").replace(" km", ""));
        return isNaN(numericValue) ? 0 : numericValue;
    };

    const [localInputValue, setLocalInputValue] = useState<string>(
        formatValueWithUnit(rangeValue)
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;

        let numericValue = parseValueFromUnit(rawValue);

        if (numericValue <= 50) {
            setLocalInputValue(rawValue);
        }

        if (numericValue >= 0.1 && numericValue <= 50) {
            onDeviationChange(Number(numericValue.toFixed(1)));
        }
    };

    const handleInputBlur = () => {

        let numericValue = parseValueFromUnit(localInputValue);

        if (numericValue > 50) {
            numericValue = 50;
        }

        setLocalInputValue(formatValueWithUnit(numericValue));
    };

    const handleSliderChange = (value: number) => {
        onDeviationChange(Number(value.toFixed(2)));
        setLocalInputValue(formatValueWithUnit(value));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = [
            "Backspace",
            "ArrowLeft",
            "ArrowRight",
            "Delete",
            ",",
        ];

        if (allowedKeys.includes(e.key)) return;

        const numericValue = parseValueFromUnit(localInputValue + e.key);

        if (isNaN(numericValue) || numericValue > 50) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (!isChecked) {
            setLocalInputValue(formatValueWithUnit(0));
            onDeviationChange(0);
        } else {
            if (localInputValue === "0,00 km") {
                setLocalInputValue(formatValueWithUnit(0.01));
            }
        }
    }, [isChecked]);

    return (
        <div className="display-flex gap-20" style={{flexDirection: 'column'}}>
            <div className="gap-20 display-flex align-items-center checkbox-container">
                <Checkbox checked={isChecked} onChange={onCheckedChange}>
                    Definir tolerância de desvio da rota
                </Checkbox>
            </div>
            {isChecked && (
                <div className="itens-container display-flex gap-20">
                    <div className="slider-container">
                        <CustomSlider
                            value={rangeValue}
                            minValue={0.1}
                            maxValue={50.0}
                            step={0.01}
                            onChange={handleSliderChange}
                        />
                    </div>

                    <div className="input-container">
                        <input
                            type="text"
                            className="form-control"
                            value={localInputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};


const DynamicContentAreas = () => {
    const [tabKey, setTabKey] = useState<string>();

    return (
        <div className="bg-blank border">
            <DataTabs
                activeKey={tabKey}
                onSelectTab={(newTabKey) => setTabKey(newTabKey)}
            >
                <DataTab tabKey="tab1" title={<Title text="" value="Opcionais"/>}>
                    <div className="contentArea">
                        {"Nenhuma área opcional adicionada"}
                    </div>
                </DataTab>
                <DataTab
                    tabKey="tab2"
                    title={<Title text="" value="Globais"/>}
                ></DataTab>
            </DataTabs>
            <br/>
            <div className="btn-toolbar">
                <Button block>Adicionar área</Button>
            </div>
        </div>
    );
};

export const Title = ({
                          text,
                          value,
                      }: {
    text: string;
    value: React.ReactNode;
}) => (
    <div>
        <div>{text}</div>
        <div className="text-bold text-size-18">{value}</div>
    </div>
);

type TProps = {
    onChangeRoutes: (routes: Route[], via: any[]) => void;
    onSave?: (
        origin: IRoute,
        destination: IRoute,
        routes: Route[],
        stops: IStop[],
        routeName: string,
        routeCost: CreateRouteCostPayload,
        accountId: string,
        routeRangeToleranceLimit: number | null
    ) => void;
    saveLabel?: string;
};

export interface IStop {
    id?: string;
    position: any;
    stayTime: string;
    category: string;
    order: number;
}

const INITIAL_STOP = {
    position: null,
    stayTime: "00:00",
    category: "LUNCH_REST",
    order: 1,
};

const RegisterRoute = ({onChangeRoutes, onSave, saveLabel = 'Salvar rota'}: TProps) => {
    const {
        origin: originStore,
        destination: destinationStore,
        stops,
        routes: routesStore,
        routeName,
        accountId,
        routeRangeToleranceLimit,
    } = useAppSelector((state) => state.routes);
    const dispatch = useDispatch();

    const [origin, setOrigin] = useState<any>(null);
    const [destination, setDestination] = useState<any>(null);
    const [routes, setRoutes] = useState<Route[]>([]);
    const [via, setVia] = useState<IStop[]>([]);
    const [distance, setDistance] = useState("");
    const [duration, setDuration] = useState("");
    const [stop, setStop] = useState({...INITIAL_STOP});
    const [invalidPosition, setInvalidPosition] = useState(false);

    const [hasRouteRangeLimit, setHasRouteRangeLimit] = useState(Boolean(routeRangeToleranceLimit))
    const [routeRangeLimit, setRouteRangeLimit] = useState(routeRangeToleranceLimit ?? 0);

    const [selectedFuelOption, setSelectedFuelOption] = useState<string | number>(0);
    const [selectedVehicleOption, setSelectedVehicleOption] = useState<string | number>(1);
    const [kmInputValue, setKmInputValue] = useState<string>("");
    const [priceInputValue, setPriceInputValue] = useState<string>("");
    const [fuelType, setFuelType] = useState<{ value: number; label: string }[]>([]);
    const [vehicleType, setVehicleType] = useState<{ value: number; label: string }[]>([]);
    const [tollValue, setTollValue] = useState<number>(0);
    const [fuelValue, setFuelValue] = useState<number>(0);
    const [totalValue, setTotalValue] = useState<number>(0);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [currencyTypeId, setCurrencyTypeId] = useState<number>(0);

    function handleHasRouteRangeLimitChange(event: ChangeEvent<HTMLInputElement>) {
        setHasRouteRangeLimit(event.target.checked)
    }

    function handleRouteRangeLimitChange(value: number) {
        setRouteRangeLimit(value)
    }

    useEffect(() => {
        const fetchCurrencyTypes = async () => {
            try {
                const response = await getCurrencyTypes();
                const realCurrency = response.find(currency => currency.name.toLowerCase().includes("real"));
                if (realCurrency) {
                    setCurrencyTypeId(realCurrency.id);
                }
            } catch (error) {
                console.error("Error fetching currency types:", error);
            }
        };

        fetchCurrencyTypes();
    }, []);

    useEffect(() => {
        if (routeRangeToleranceLimit && routeRangeToleranceLimit > 0) {
            setHasRouteRangeLimit(true)
            setRouteRangeLimit((routeRangeToleranceLimit))
        } else {
            setHasRouteRangeLimit(false);
            setRouteRangeLimit(0);
        }
    }, [routeRangeToleranceLimit])

    useEffect(() => {
        setOrigin(originStore || null);
        setDestination(destinationStore || null);
        setVia(stops || []);
    }, [originStore, destinationStore, stops]);

    useEffect(() => {
        if (origin && destination) {
            handleRoute();
        } else {
            setRoutes([]);
            setDistance(convertMetersToKm(0));
            setDuration(convertSecondsToHours(0));
        }
    }, [origin, destination, via]);

    const saveChanges = () => {
        if (!origin || !destination) return;

        const saveOrigin = {lat: origin.lat, lng: origin.lng, label: origin.label};
        dispatch(defineOrigin(saveOrigin));

        const saveDestination = {
            lat: destination.lat,
            lng: destination.lng,
            label: destination.label,
        };
        dispatch(defineDestination(saveDestination));

        dispatch(defineStops(via));

        dispatch(defineRouteDeviation(routeRangeLimit ?? 0))

        const saveRouteCost: CreateRouteCostPayload = {
            route: "",
            costTypes: [parseInt(selectedFuelOption as string)],
            valuePerUnit: {
                [selectedFuelOption.toString()]: [
                    parseFloat(kmInputValue.replace(/[^0-9.,]/g, "")),
                    parseFloat(priceInputValue.replace(/[^0-9.,]/g, "").replace(",", ".")),
                ],
            },
            totalCost: totalValue,
            tollCost: tollValue,
            currencyType: currencyTypeId
        }
        dispatch(defineRouteCost(saveRouteCost))

        if (onSave) {
            onSave(
                saveOrigin,
                saveDestination,
                routes,
                via,
                routeName,
                saveRouteCost,
                accountId,
                routeRangeLimit ?? 0,
            );
        }
    };

    useEffect(() => {
        onChangeRoutes(routes, via);
        dispatch(defineRoutes(routes));
    }, [routes]);

    const handleRoute = async () => {
        if (origin && destination) {
            const body = {
                origin: {lat: origin.lat, lng: origin.lng},
                destination: {lat: destination.lat, lng: destination.lng},
                via: via.map((t) => ({lat: t.position.lat, lng: t.position.lng})),
                vehicle: {},
                departureTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                routeDeviation: routeRangeLimit ?? null,
            };
            const response = await mountRoute(body);
            setRoutes(response.routes);
            const {distance, duration} = (
                response.routes[0]?.sections || []
            ).reduce(
                (acum, curr) => {
                    acum.distance += curr.summary.length;
                    acum.duration += curr.summary.duration;
                    return acum;
                },
                {distance: 0, duration: 0}
            );
            setDistance(convertMetersToKm(distance));
            setDuration(convertSecondsToHours(duration));
        }
    };

    const convertSecondsToHours = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        return `${hours}h${minutes}min`;
    };

    const convertMetersToKm = (valueInMeters: number) => {
        if (valueInMeters < 1000) {
            return `${valueInMeters} M`;
        } else {
            const valueInKm = (valueInMeters / 1000).toFixed(2);
            return `${valueInKm} Km`;
        }
    };

    const [openModal, setOpenModal] = useState(false);

    const DynamicContent = () => {
        return (
            <fieldset className="col-sm-4">
                <p>
                    <Checkbox label="Rota com menor consumo energético"/>
                </p>
                <p>
                    <Checkbox label="Rota mais rápida"/>
                </p>
                <p>
                    <Checkbox label="Rota mais econômica"/>
                </p>
            </fieldset>
        );
    };

    const DynamicContentPreferencias = () => {
        return (
            <fieldset className="col-sm-4">
                <p>
                    <Checkbox label="Evitar pedágio"/>
                </p>
            </fieldset>
        );
    };

    const fetchDataAutosuggestAddress = async (text: string) => {
        const response = await fetchAutosuggest(text);
        const data: AutoSuggestSuggestion[] = [];
        response.forEach((i) => data.push({label: i.title, description: i.address.label, ...i} as any));
        return data;
    };

    const onClear = (index: number) => {
        setVia((previous) => {
            const via = previous.filter((_, i) => index !== i);
            return via.map((item, i) => ({...item, order: i + 1}));
        });
    };

    const formatTimeToText = (time: string): string => {
        const [hours, minutes] = time.split(":").map(Number);
        const hourText = hours > 0 ? `${hours} hora${hours > 1 ? "s" : ""}` : "";
        const minuteText =
            minutes > 0 ? `${minutes} minuto${minutes > 1 ? "s" : ""}` : "";

        return [hourText, minuteText].filter(Boolean).join(" e ");
    };

    const formatCategory = (text: string) => {
        const DICT = {
            LUNCH_REST: "Refeição",
            LOAD_UNLOAD: "Coleta de carga",
            END_OF_DAY: "Fim de jornada de trabalho",
            DEALER: "Concessionária",
        };

        return DICT[text as "LUNCH_REST" | "LOAD_UNLOAD" | "END_OF_DAY" | "DEALER"];
    };

    const reorderArray = (index: number, direction: 1 | -1) => {
        if (index < 0 || index >= via.length) return;

        const newIndex = index + direction;
        if (newIndex < 0 || newIndex >= via.length) return;

        const newArray = [...via];
        [newArray[index], newArray[newIndex]] = [
            newArray[newIndex],
            newArray[index],
        ];

        setVia(newArray);
    };

    return (
        <>
            <div className="gap-10" style={{display: 'flex', flexDirection: 'column'}}>
                <div className="callout callout-primary border-none display-flex align-items-center shadow-smooth">
                    <div className="flex-1">
                        <div className="text-medium text-size-20 line-height-125rel display-flex align-items-center">
                            <span className="rioglyph rioglyph-road text-size-16 margin-right-10"></span>
                            <span className="text-size-16 text-medium line-height-15">
                Distância: {distance || "0KM"}
              </span>
                        </div>

                        <div>
                            {/* <span className="rioglyph text-color-gray rioglyph-filling-e-station text-size-16 margin-right-10"></span>
              <span className="text-color-gray text-size-12 line-height-15">
                Estimativa média de consumo de combustível: 0l
              </span> */}
                        </div>

                        <div>
                            {/* <span className="rioglyph text-color-gray rioglyph-globe text-size-16 margin-right-10"></span>
              <span className="text-color-gray text-size-12 line-height-15">
                Estimativa média de emissão de carbono: 0kg CO2
              </span> */}
                        </div>
                    </div>
                    <div
                        className="display-flex justify-content-center"
                        style={{flex: 1}}
                    >
                        <h1 className="text-color-primary ">{duration || "0h00m"}</h1>
                    </div>
                </div>

                <div className="gap-10" style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        <label className="text-color-dark">Origem</label>

                        <div>
                            <div className="display-flex align-items-center">
                                <Autosuggest
                                    fetchData={fetchDataAutosuggestAddress}
                                    placeholder="Informe a origem"
                                    icon="rioglyph-start"
                                    defaultValue={origin?.label || ""}
                                    onClear={() => setOrigin(null)}
                                    onSuggestionSelected={(e, i) => {
                                        setOrigin({
                                            ...(i.suggestion as any).position,
                                            ...i.suggestion,
                                        });
                                    }}
                                />
                                <div className="btn-toolbar">
                                    <Button
                                        bsStyle={Button.PRIMARY}
                                        iconOnly
                                        iconName="rioglyph-plus-light"
                                        onClick={() => setOpenModal(true)}
                                        className="margin-left-10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {via.map((item, index) => (
                        <div>
                            <div className="display-flex align-items-center">
                                <div className="width-40"></div>
                                <label className="text-color-dark">
                                    {formatCategory(item.category)} -{" "}
                                    {formatTimeToText(item.stayTime)}
                                </label>
                            </div>
                            <div className="display-flex align-items-center">
                                <div className="width-40 padding-left-10">
                                    {via.length > 1 ? (
                                        <>
                                            <div
                                                style={{color: "#30B4C0", cursor: "pointer"}}
                                                className="rioglyph rioglyph-chevron-up"
                                                onClick={() => reorderArray(index, -1)}
                                            ></div>
                                            <div
                                                style={{color: "#30B4C0", cursor: "pointer"}}
                                                className="rioglyph rioglyph-chevron-down"
                                                onClick={() => reorderArray(index, 1)}
                                            ></div>
                                        </>
                                    ) : null}
                                </div>
                                <Autosuggest
                                    fetchData={fetchDataAutosuggestAddress}
                                    placeholder="Informe a parada"
                                    icon="rioglyph-pushpin"
                                    defaultValue={item?.position?.label || ""}
                                    onClear={() => {
                                        onClear(index);
                                    }}
                                    onSuggestionSelected={(e, i) => {
                                        // setOrigin({
                                        //   ...(i.suggestion as any).position,
                                        //   ...i.suggestion,
                                        // });
                                    }}
                                />
                                <div className="btn-toolbar">
                                    <Button
                                        bsStyle={Button.PRIMARY}
                                        iconOnly
                                        iconName="rioglyph-plus-light"
                                        onClick={() => setOpenModal(true)}
                                        className="margin-left-10"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <div>
                        <label className="text-color-dark">Destino</label>
                        <Autosuggest
                            fetchData={fetchDataAutosuggestAddress}
                            placeholder="Informe o destino"
                            icon="rioglyph-finish"
                            defaultValue={destination?.label || ""}
                            onClear={() => setDestination(null)}
                            onSuggestionSelected={(e, i) => {
                                setDestination({
                                    ...(i.suggestion as any).position,
                                    ...i.suggestion,
                                });
                            }}
                        />
                    </div>
                    <div className="separator ">
                        <ExpanderPanel
                            title="Otimizar"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <DynamicContent/>
                        </ExpanderPanel>

                        <ExpanderPanel
                            title="Preferências"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <DynamicContentPreferencias/>
                        </ExpanderPanel>

                        <ExpanderPanel
                            title="Desvio da rota"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <DynamicContentDesvio
                                isChecked={hasRouteRangeLimit}
                                onCheckedChange={handleHasRouteRangeLimitChange}
                                onDeviationChange={handleRouteRangeLimitChange}
                                rangeValue={routeRangeLimit}
                            />
                        </ExpanderPanel>

                        <ExpanderPanel
                            title="Áreas"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <DynamicContentAreas/>
                        </ExpanderPanel>

                        <ExpanderPanel
                            title="Simulador de custos"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <DynamicContentSimuilador
                                distance={distance}
                                selectedFuelOption={selectedFuelOption}
                                setSelectedFuelOption={setSelectedFuelOption}
                                selectedVehicleOption={selectedVehicleOption}
                                setSelectedVehicleOption={setSelectedVehicleOption}
                                kmInputValue={kmInputValue}
                                setKmInputValue={setKmInputValue}
                                priceInputValue={priceInputValue}
                                setPriceInputValue={setPriceInputValue}
                                fuelType={fuelType}
                                setFuelType={setFuelType}
                                vehicleType={vehicleType}
                                setVehicleType={setVehicleType}
                                tollValue={tollValue}
                                setTollValue={setTollValue}
                                fuelValue={fuelValue}
                                setFuelValue={setFuelValue}
                                totalValue={totalValue}
                                setTotalValue={setTotalValue}
                                showResults={showResults}
                                setShowResults={setShowResults}
                                origin={origin}
                                destination={destination}
                                stops={via}
                            />
                        </ExpanderPanel>

                        <div className="btn-toolbar" style={{paddingTop: '16px'}}>
                            <Button
                                onClick={saveChanges}
                                block
                                bsStyle={Button.PRIMARY}
                            >
                                {saveLabel}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                show={openModal}
                title={"Adicionar parada"}
                onClose={() => setOpenModal(false)}
                showCloseButton
                bsSize="md"
                body={
                    <div className="display-flex">
                        <div
                            style={{
                                width: 56,
                                height: 56,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: 24,
                                marginLeft: 24,
                            }}
                            className="border border-color-primary border-width-3
                       text-center"
                        >
              <span
                  className="rioglyph rioglyph-pushpin text-color-primary"
                  style={{fontSize: 32}}
              />
                        </div>
                        <div className="display-grid gap-10" style={{flex: 1}}>
                            <div>
                                <label className="text-color-dark">Local da parada</label>
                                <Autosuggest
                                    fetchData={fetchDataAutosuggestAddress}
                                    placeholder="Informe a parada"
                                    onClear={() => {
                                        setStop((previous) => ({
                                            ...previous,
                                            position: null,
                                        }));
                                    }}
                                    onSuggestionSelected={(e, i) => {
                                        setInvalidPosition(false);
                                        setStop((previous) => ({
                                            ...previous,
                                            position: {
                                                ...(i.suggestion as any).position,
                                                ...i.suggestion,
                                            },
                                        }));
                                    }}
                                />
                                {invalidPosition ? (
                                    <div style={{
                                        color: 'red',
                                        fontSize: '11px',
                                        marginTop: '8px',
                                    }}>Campo obrigatório</div>
                                ) : null}
                            </div>
                            <div className="form-group padding-left-0">
                                <label htmlFor="select">Tipo de parada</label>
                                <select
                                    className="form-control input-lg"
                                    id="select"
                                    onChange={(event) => {
                                        setStop((previous) => ({
                                            ...previous,
                                            category: event.target.value,
                                        }));
                                    }}
                                >
                                    <option value="LUNCH_REST">Refeição</option>
                                    <option value="LOAD_UNLOAD">Coleta de carga</option>
                                    <option value="END_OF_DAY">Fim de jornada de trabalho</option>
                                    <option value="DEALER">Concessionária</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="time">Tempo de permanência</label>
                                <input
                                    id="time"
                                    type="time"
                                    className="form-control input-lg"
                                    min="00:00"
                                    max="23:59"
                                    value={stop.stayTime}
                                    onChange={(event) => {
                                        setStop((previous) => ({
                                            ...previous,
                                            stayTime: event.target.value?.toLocaleString(),
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
                footer={
                    <div className="display-flex gap-10 justify-content-end">
                        <Button
                            bsStyle="primary"
                            className="btn btn-primary btn-outline btn-lg width-100"
                            onClick={() => {
                                setStop({...INITIAL_STOP});
                                setOpenModal(false);
                                setInvalidPosition(false);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            disabled={stop.stayTime === "00:00"}
                            bsStyle="primary"
                            className="btn-lg width-100"
                            onClick={() => {
                                if (!stop.position) {
                                    setInvalidPosition(true);
                                    return;
                                }
                                setStop({...INITIAL_STOP});
                                setInvalidPosition(false);
                                setVia((previous) => [
                                    ...previous,
                                    {...stop, order: previous.length + 1},
                                ]);
                                setOpenModal(false);
                            }}
                        >
                            Salvar
                        </Button>
                    </div>
                }
            />
        </>
    );
};
export default RegisterRoute;
